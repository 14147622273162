<template>
  <div>
    <a
      v-if="!isOpen"
      @click="toggle"
      data-test-id="header-search-button"
      class="has-text-info"
    >
      <span class="icon menu-item">
        <i class="fas fa-search"></i>
      </span>
      <span class="menu-item">Search again</span>
    </a>
    <AppHeaderSearchOptions
      v-if="isOpen && !tab"
      @selectType="tab = $event"
      @close="toggle"
    />
    <AppHeaderSearchForm v-if="isOpen && tab" :tab="tab" @close="tab = ''" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AppHeaderVehicleSearch',
  components: {
    AppHeaderSearchOptions: () => import('./AppHeaderSearchOptions'),
    AppHeaderSearchForm: () => import('./AppHeaderSearchForm')
  },
  data: () => ({
    isOpen: false,
    tab: ''
  }),
  mounted() {
    this.getTab()
  },
  computed: {
    ...mapGetters('auth', [
      'isCompanionUser',
      'hasAutoCheck',
      'hasAutoCheckValuation',
      'hasMotorCheck'
    ])
  },
  methods: {
    getTab() {
      if (
        !this.isCompanionUser &&
        !this.hasAutoCheck &&
        !this.hasAutoCheckValuation &&
        !this.hasMotorCheck
      ) {
        this.tab = 'vpp'
      } else this.tab = ''
    },
    toggle() {
      this.isOpen = !this.isOpen
      if (!this.isOpen && this.tab) this.getTab()
    }
  }
}
</script>
